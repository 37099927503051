import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  ScrollArea,
  Select,
  Text,
} from '@mantine/core';
import {
  IconBath,
  IconBed,
  IconCashBanknote,
  IconCircleCheck,
  IconDownload,
  IconFlag,
  IconHome,
  IconLoader,
  IconStatusChange,
} from '@tabler/icons-react';
import {
  useContacts,
  useExportToCRM,
  useLeadContract,
  useLeadProfilePage,
  useOrganisationStore,
  useUpdateLead,
} from 'fe/queries';
import { useEffect } from 'react';
import classes from './ConversationDetailSidenav.module.scss';
import { Products, StatusOfCampaignPropertyLabels } from 'shared/db';
import { formatUIPrice } from 'shared/formatter';
import { notifications } from '@mantine/notifications';
import { trackLeadStatusChange } from 'fe/analytics';
import { PropertyItem } from './Sidenav/PropertyInfoItem';
import { ContactCard } from './Sidenav/ContactCard';

const CampaignTypeMapping: Record<Products, string> = {
  [Products.LISTINGS]: 'Active Listings',
  [Products.EXPIRED]: 'Expired Listings',
  [Products.COMMERCIAL]: 'Commercial Listings',
  [Products.FSBO]: 'FSBO Listings',
};

interface ConversationDetailSidenavProps {
  leadId: number;
}

export function ConversationDetailSidenav({
  leadId,
}: ConversationDetailSidenavProps) {
  const { data: lead } = useLeadProfilePage({ lead_id: leadId });
  const { data: contacts } = useContacts({ lead_id: leadId });
  const {
    mutateAsync: updateLead,
    isPending: updatingLead,
    isSuccess: leadWasUpdated,
    reset: resetLeadUpdateQuery,
  } = useUpdateLead(leadId);
  const { organisation } = useOrganisationStore();
  const { mutateAsync: exportToCRM, isPending: loadingExport } =
    useExportToCRM();

  const { data: contractPDF, isLoading: isLoadingContract } = useLeadContract({
    lead_id: leadId,
  });

  let SelectIcon = <IconStatusChange size="1rem" />;
  if (updatingLead) {
    SelectIcon = <IconLoader color="blue" size="1rem" />;
  } else if (leadWasUpdated) {
    SelectIcon = <IconCircleCheck color="green" size="1rem" />;
  }

  useEffect(() => {
    if (leadWasUpdated) {
      setTimeout(() => {
        resetLeadUpdateQuery();
      }, 1000);
    }
  }, [leadWasUpdated]);

  if (!lead) return null;

  return (
    <Flex
      direction="column"
      className={classes.wrapper}
      justify="space-between"
      h="100%"
      py="sm"
    >
      <Flex direction="column" px="sm">
        <Select
          aria-label="Select status"
          disabled={updatingLead}
          mb="md"
          size="sm"
          label="Lead status"
          placeholder="Pick a new status"
          value={lead.status}
          icon={SelectIcon}
          onChange={async (value) => {
            if (value) {
              const oldValue = lead?.status || null;
              await updateLead({ status: value, lead_id: leadId });
              trackLeadStatusChange({
                old_status: oldValue,
                new_status: value,
                street_address: lead.address.street_address,
                zip_code: lead.address.zip_code,
              });
              notifications.show({
                title: 'Status updated',
                message: 'The status has been updated successfully',
              });
            }
          }}
          data={StatusOfCampaignPropertyLabels}
        />
      </Flex>

      <Box style={{ position: 'relative', flex: 1 }}>
        <ScrollArea
          style={{ position: 'absolute', height: '100%', width: '100%' }}
          px="sm"
        >
          <Flex
            direction="column"
            justify="space-between"
            h="100%"
            pb="lg"
            gap="xs"
          >
            <Box>
              <Flex direction="column" gap="xs">
                {contacts?.map((contact) => (
                  <ContactCard
                    leadId={leadId}
                    key={contact.id}
                    contact_id={contact.id}
                    name={contact.name}
                    email={contact.emails[0]}
                    phones={contact.phones}
                    sms_enabled={contact.sms_enabled}
                  />
                ))}
              </Flex>
            </Box>

            <Divider my="xs" />

            {lead.property_info.status === 'Active' && (
              <PropertyItem
                icon={<IconCashBanknote />}
                label="Listed price"
                value={formatUIPrice(lead.property_info.price)}
                color="green"
              />
            )}

            <PropertyItem
              icon={<IconCashBanknote />}
              label="Offer price"
              value={formatUIPrice(lead.offer_price)}
              color="grape"
            />

            <PropertyItem
              icon={<IconFlag />}
              label="Listing status"
              value={lead.property_info.status}
              color="blue"
            />

            <PropertyItem
              icon={<IconHome />}
              label="Property Type"
              value={lead.property_info.type}
              color="pink"
            />
            <Flex gap="xs">
              <PropertyItem
                icon={<IconBed />}
                label="Beds"
                value={lead.property_info.bedrooms || 0}
                color="yellow"
              />
              <PropertyItem
                icon={<IconBath />}
                label="Baths"
                value={lead.property_info.bathrooms || 0}
                color="orange"
              />
            </Flex>
            {/*  */}
          </Flex>
        </ScrollArea>
      </Box>

      <Flex px="sm" mt="sm" direction="column" gap="lg">
        {lead.source && (
          <div>
            <Badge size="sm" variant="transparent" radius="xs" fullWidth>
              {lead.source.type &&
                CampaignTypeMapping[lead.source.type as Products]}
            </Badge>
            <Text fz="sm" align="center">
              {lead.source.name}
            </Text>
          </div>
        )}

        {contractPDF ? (
          <Button
            color="gray.7"
            variant="subtle"
            loading={isLoadingContract}
            onClick={() => {
              const a = document.createElement('a');
              a.href = 'data:application/pdf;base64,' + contractPDF;
              a.download = `Contract for ${lead.address.street_address}, ${lead.address.zip_code}.pdf`;
              a.click();
            }}
            compact
            leftIcon={<IconDownload size="1rem" />}
          >
            Download contract
          </Button>
        ) : null}

        {organisation?.lead_webhook_url && (
          <Button
            loading={loadingExport}
            onClick={() => exportToCRM({ lead_id: leadId })}
            variant="outline"
            fullWidth
            size="sm"
            compact
          >
            Export to CRM
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
