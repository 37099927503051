import { ActionIcon, Box, Divider, Flex, Loader, Paper } from '@mantine/core';
import {
  useDeleteOrganisationWorkflow,
  useOrganisationStore,
  useOrganisationWorkflows,
} from 'fe/queries';
import { Typography } from 'fe/ui/shared';
import {
  IconCircleCheck,
  IconPencil,
  IconPlus,
  IconX,
} from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { WorkflowForm } from './WorkflowForm';
import { WorkflowSteps } from './WorkflowSteps';
import { useEffect, useState } from 'react';

interface WorkflowManagementProps {
  title?: string;
  onChange?: (value: number) => void;
  isLoadingChange?: boolean;
  selectedValue?: number | null;
}

export function WorkflowManagement({
  title = 'Workflows',
  onChange,
  isLoadingChange,
  selectedValue,
}: WorkflowManagementProps) {
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;

  const [internalValue, setInternalValue] = useState<number | null>(
    selectedValue || null,
  );

  useEffect(() => {
    if (selectedValue) {
      setInternalValue(selectedValue);
    }
  }, [selectedValue]);

  const { data: workflows } = useOrganisationWorkflows(organisation.id);
  const { mutateAsync: deleteWorkflow, isPending: isDeleting } =
    useDeleteOrganisationWorkflow();

  const open = (workflow?: { id: number; name: string }) => {
    modals.open({
      title: workflow ? 'Update workflow' : 'Create workflow',
      children: (
        <WorkflowForm onSuccess={() => modals.closeAll()} workflow={workflow} />
      ),
    });
  };

  return (
    <Flex display="flex" h="100%" py="md">
      <Box px="xl" maw={800} w="100%">
        <Flex align="center" justify="space-between">
          <Typography variant="label">{title}</Typography>
          <ActionIcon variant="filled" color="brand" onClick={() => open()}>
            <IconPlus size="1.125rem" />
          </ActionIcon>
        </Flex>

        <Flex mt="xl" direction="column" gap="md">
          {workflows?.map((workflow) => {
            const isSelected = internalValue && internalValue === workflow.id;
            return (
              <Paper
                onClick={() => {
                  if (onChange) {
                    setInternalValue(workflow.id);
                    onChange(workflow.id);
                  }
                }}
                key={workflow.id}
                p="xs"
                w="100%"
                radius="md"
                sx={(theme) => ({
                  border: isSelected
                    ? '2px solid ' + theme.colors['brand'][6]
                    : 'none',
                  position: 'relative',
                  cursor: onChange ? 'pointer' : 'pointer',
                  ':hover': {
                    border: !isSelected
                      ? '1px solid ' + theme.colors['brand'][6]
                      : '',
                  },
                })}
                shadow={onChange && !isSelected ? 'sm' : undefined}
              >
                <Flex justify="space-between" w="100%" align="center">
                  <Flex direction="column">
                    <Typography fz="xs">Workflow Name</Typography>
                    <Typography>{workflow.name}</Typography>
                  </Flex>

                  <Flex gap="xs">
                    <ActionIcon
                      variant="outline"
                      color="brand"
                      onClick={() => open(workflow)}
                    >
                      <IconPencil size="1.125rem" />
                    </ActionIcon>
                    <ActionIcon
                      variant="light"
                      color="gray"
                      loading={isDeleting}
                      onClick={() =>
                        deleteWorkflow({ workflow_id: workflow.id })
                      }
                    >
                      <IconX size="1.125rem" />
                    </ActionIcon>
                  </Flex>
                </Flex>
                <Divider my="md" />
                <WorkflowSteps workflowId={workflow.id} />
                {isSelected && (
                  <Box
                    sx={(theme) => ({
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      borderRadius: 50,
                      width: '1.5rem',
                      height: '1.5rem',
                      background: theme.colors['brand'][6],
                    })}
                  >
                    {isLoadingChange ? (
                      <Box pl=".25rem" pt=".1rem">
                        <Loader size="1rem" color="white" />
                      </Box>
                    ) : (
                      <IconCircleCheck size="1.5rem" color="white" />
                    )}
                  </Box>
                )}
              </Paper>
            );
          })}
        </Flex>
      </Box>
    </Flex>
  );
}
