import {
  archiveCampaign,
  Campaign,
  fetchSingleCampaign,
  updateCampaign,
} from 'shared/db';
import { callApi, supabase } from '../utils';
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { generateCampaignLeadsEndpoint } from 'shared/api';
import { trpc } from '../setup';
import { modals } from '@mantine/modals';

const campaignKeys = {
  single: (id: number) => ['campaign', id] as const,
};

export const useCampaigns = (organization_id: number | undefined) =>
  trpc.organization.campaigns.getAll.useQuery(
    organization_id
      ? {
          organization_id,
        }
      : skipToken,
  );

export const useGenerateCampaignProperties = () =>
  useMutation({
    mutationFn: (campaign_id: number) =>
      callApi(generateCampaignLeadsEndpoint, {
        campaign_id,
      }),
  });

export const useUpdateCampaign = () => {
  const queryClient = useQueryClient();
  const utils = trpc.useUtils();

  return trpc.organization.campaigns.update.useMutation({
    onSuccess: async (data, params) => {
      utils.organization.campaigns.getAll.setData(
        { organization_id: data.organisation_id },
        (previous) =>
          previous?.map((prevCampaign) =>
            prevCampaign.id === data.id
              ? { ...data, incomplete: prevCampaign.incomplete }
              : prevCampaign,
          ) || [],
      );
      queryClient.setQueriesData(
        { queryKey: campaignKeys.single(data.id) },
        () => data,
      );

      utils.organization.campaigns.getCampaignLocations.setData(
        { campaign_id: data.id },
        () => params.zip_codes || [],
      );
    },
  });
};

export const useCreateCampaign = () => {
  const utils = trpc.useUtils();
  return trpc.organization.campaigns.create.useMutation({
    onSuccess: (data) => {
      utils.organization.campaigns.getAll.setData(
        { organization_id: data.organisation_id },
        (previous) => [...(previous || []), { ...data, incomplete: true }],
      );
      modals.closeAll();
    },
  });
};

export const useArchiveCampaign = () => {
  const utils = trpc.useUtils();
  return useMutation({
    mutationFn: (campaign: Campaign) => archiveCampaign(supabase, campaign),
    onSuccess: (data) => {
      utils.organization.campaigns.getAll.setData(
        { organization_id: data.organisation_id },
        (previous) => [
          ...(previous || []).filter(
            (prevCampaign) => prevCampaign.id !== data.id,
          ),
        ],
      );
    },
  });
};

export const useCampaign = (id: number | string) =>
  useQuery({
    queryKey: campaignKeys.single(Number(id)),
    queryFn: () => fetchSingleCampaign(supabase, Number(id)),
  });

// Create todo
export async function uploadCampaignContract(
  attachment: File,
  campaign: Campaign,
) {
  const contractFilename = `private/${campaign.organisation_id}/${attachment.name}`;
  const { error } = await supabase.storage
    .from('contracts')
    .upload(contractFilename, attachment, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload contract");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { usa_zip_codes, ...baseCampaign } = campaign as any;

  await updateCampaign(supabase, {
    ...baseCampaign,
    contract_filename: contractFilename,
  });

  return { campaign, filename: contractFilename };
}

export const useCampaignContractUpload = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      contractFile,
      campaign,
    }: {
      contractFile: File;
      campaign: Campaign;
    }) => {
      return uploadCampaignContract(contractFile, campaign);
    },
    onSuccess: ({ campaign, filename }) => {
      // queryClient.setQueryData(['email_templates', { id: data.id }], data)
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns'] },
        (previous) => {
          return [
            ...(previous?.map((prevCampagin) => {
              return {
                ...prevCampagin,
                contract_filename:
                  campaign.id === prevCampagin.id
                    ? filename
                    : prevCampagin.contract_filename,
              };
            }) || []),
          ];
        },
      );
    },
  });
};
