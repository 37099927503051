import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CampaignFilters,
  fetchCampaignFilters,
  PropertyCondition,
} from 'shared/db';
import { supabase } from '../utils';

export interface CampaignFilterForm {
  time_on_market?: number | null;
  property_types?: string[];
  min_beds?: number | null;
  max_beds?: number | null;
  min_baths?: number | null;
  max_baths?: number | null;
  min_price?: number | null;
  max_price?: number | null;
  min_sqft?: number | null;
  max_sqft?: number | null;
  min_year_built?: number | null;
  max_year_built?: number | null;
  max_hoa?: number | null;
  lease?: string | null;
  keywords?: string;
  community_55?: string;
  equity_purchase?: string;
  club_membership?: string;
  min_lot_sqft?: number | null;
  max_lot_sqft?: number | null;
  must_have_garage: string;
  property_condition: PropertyCondition[] | null;
}

export async function updateCampaignFilters(
  filters: CampaignFilters,
): Promise<CampaignFilters> {
  const { data, error } = await supabase
    .from('campaign_filters')
    .upsert(filters)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't update campaign filters");
  }

  return data;
}

export const useUpdateCampaignFilters = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCampaignFilters,
    onSuccess: (data) => {
      queryClient.setQueriesData<CampaignFilters>(
        { queryKey: ['campaign_filters'] },
        () => data,
      );
    },
  });
};

export const useCampaignFilters = (campaignId: number) =>
  useQuery({
    staleTime: Infinity,
    queryKey: ['campaign_filters', campaignId],
    queryFn: () => fetchCampaignFilters(supabase, campaignId),
  });
