import { Box, Button, Flex, Input, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useUpdateContact } from 'fe/queries';
import { IMaskInput } from 'react-imask';
import { formatPhoneNumberToNational } from 'shared/formatter';
import { zodPhoneNumberNullable } from 'shared/validators';
import { z } from 'zod';

interface ContactFormProps {
  leadId: number;
  id: number;
  name: string;
  email: string;
  phones: string[];
  onClose: () => void;
}

export function ContactForm({
  leadId,
  id,
  name,
  email,
  phones,
  onClose,
}: ContactFormProps) {
  const { mutateAsync: update, isPending } = useUpdateContact(leadId);
  const schema = z.object({
    name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
    email: z.string().email({ message: 'Invalid email' }),
    phones: z.array(zodPhoneNumberNullable),
  });
  const form = useForm({
    initialValues: {
      email,
      name,
      phones: phones
        .map(formatPhoneNumberToNational)
        .filter((val) => val !== undefined),
    },
    validate: zodResolver(schema),
  });

  const onFormSubmit = form.onSubmit(async (values) => {
    await update({
      contact_id: id,
      name: values.name,
      emails: [values.email],
      phones: values.phones,
    });
    onClose();
  });

  const addPhone = () => {
    form.insertListItem('phones', '');
  };

  return (
    <Box>
      <form onSubmit={onFormSubmit}>
        <Flex gap="xs" direction="column">
          <TextInput
            label="Name"
            placeholder="John Doe"
            variant="filled"
            {...form.getInputProps('name')}
          />

          <TextInput
            label="Email"
            placeholder="john@gmail.com"
            variant="filled"
            {...form.getInputProps('email')}
          />

          <Box>
            {form.values.phones.map((_, index) => (
              <Box key={index} mb="xs">
                <Flex gap="xs" align="flex-end">
                  <Box style={{ flex: 1 }}>
                    <Input.Wrapper
                      id={`phone-input-${index}`}
                      label={index === 0 ? 'Phone' : ''}
                    >
                      <Input
                        id={`phone-input-${index}`}
                        variant="filled"
                        placeholder="(555) 555-5555"
                        component={IMaskInput}
                        mask="(000) 000-0000"
                        {...form.getInputProps(`phones.${index}`)}
                        onAccept={(value) => {
                          form.setFieldValue(`phones.${index}`, value);
                        }}
                      />
                    </Input.Wrapper>
                  </Box>

                  {form.values.phones.length > 1 && (
                    <Button
                      variant="subtle"
                      color="red"
                      size="xs"
                      onClick={() => form.removeListItem('phones', index)}
                      style={{ marginBottom: 2 }}
                    >
                      Remove
                    </Button>
                  )}
                </Flex>
              </Box>
            ))}

            <Button
              variant="subtle"
              size="xs"
              mt="xs"
              color="gray"
              compact
              onClick={addPhone}
            >
              Add phone number
            </Button>
          </Box>

          <Button mt="md" type="submit" loading={isPending}>
            SAVE
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
