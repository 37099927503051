import {
  Button,
  Flex,
  ScrollArea,
  Box,
  SegmentedControl,
  TextInput,
} from '@mantine/core';
import { useOrganisationStore } from 'fe/queries';
import { BasicCampaignFormExtra } from './BasicCampaignFormExtra';
import { useCampaignForm } from './useCampaignForm';
import { modals } from '@mantine/modals';
import { DeleteCampaignConfirmation } from './DeleteCampaignConfirmation';
import { Products } from 'shared/db';
import { LocationsSelector } from '../LocationSelector/LocationSelector';

interface CampaignFormProps {
  campaign?: any;
}

export function CampaignForm({ campaign }: CampaignFormProps) {
  const { organisation } = useOrganisationStore();
  const { form, isLoading, onSubmit } = useCampaignForm({ campaign });

  const openDeleteModal = () =>
    modals.open({
      title: 'Archive campaign?',
      children: (
        <DeleteCampaignConfirmation
          campaign={campaign}
          onClose={modals.closeAll}
        />
      ),
    });

  if (!organisation) return null;

  return (
    <form onSubmit={onSubmit} style={{ width: '100%', height: '95%' }}>
      <ScrollArea w="100%" h="100%">
        <Box>
          <SegmentedControl
            fullWidth
            mb="sm"
            data={[
              { label: 'Active listings', value: Products.LISTINGS },
              { label: 'FSBO', value: Products.FSBO },
            ]}
            {...form.getInputProps('type')}
          />

          <TextInput
            mb="sm"
            withAsterisk
            label="Name"
            placeholder="Campaign name"
            {...form.getInputProps('name')}
          />

          <LocationsSelector
            organisationId={organisation.id}
            inputProps={form.getInputProps('locations')}
            fetchCampaignLocations
            type={form.values.type as Products}
          />
        </Box>

        <BasicCampaignFormExtra form={form} organisation={organisation} />

        <Flex w={'100%'} justify={'flex-end'} mt="xl" gap="md">
          {campaign && (
            <Button
              loading={isLoading}
              onClick={openDeleteModal}
              color="red"
              variant="outline"
            >
              Archive
            </Button>
          )}

          <Button type={'submit'} loading={isLoading}>
            {campaign ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </ScrollArea>
    </form>
  );
}
