import { trpc } from '../setup';

export const useContacts = trpc.lead.getContacts.useQuery;

export const useUpdateContact = (lead_id: number) => {
  const utils = trpc.useUtils();
  return trpc.lead.updateContact.useMutation({
    onSuccess: (data) => {
      utils.lead.getContacts.setData({ lead_id }, (previous) =>
        previous?.map((contact) =>
          contact.id === data.id ? { ...contact, ...data } : contact,
        ),
      );
    },
  });
};
