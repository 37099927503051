import { z } from 'zod';

export enum PropertyCondition {
  Poor = 'Poor',
  Good = 'Good',
  Rehabbed = 'Rehabbed',
  New = 'New',
}

export enum PropertySource {
  ZILLOW = 'zillow',
  DATAFINITI = 'datafiniti',
}

export enum PropertyStatus {
  FOR_SALE = 'for_sale',
  RECENTLY_SOLD = 'recently_sold',
  UNDER_CONTRACT = 'under_contract',
  OFF_MARKET = 'off_market',
  /** @deprecated Use OFF_MARKET instead */
  SOLD = 'sold',
  /** @deprecated Use OFF_MARKET instead */
  EXPIRED = 'expired',
}

export enum PropertyType {
  SINGLE_FAMILY = 'Single Family',
  MULTI_FAMILY = 'Multi Family',
  TOWNHOUSE = 'Townhouse',
  APARTMENT = 'Apartment',
  MANUFACTURED = 'Manufactured',
  CONDO = 'Condo',
  LOT = 'Lot',
}

export const propertyConditionSchema = z.nativeEnum(PropertyCondition);
export const propertySourceSchema = z.nativeEnum(PropertySource);
export const propertyStatusSchema = z.nativeEnum(PropertyStatus);
export const propertyTypeSchema = z.nativeEnum(PropertyType);

export const propertySchema = z.object({
  mls_id: z.string().nullable(),
  zpid: z.union([z.string(), z.number()]).nullable(),
  street_address: z.string(),
  zip_code: z.number(),
  price: z.number(),
  listed_date: z.union([z.string(), z.date()]).nullable(),
  bathrooms: z.number().nullable(),
  bedrooms: z.number().nullable(),
  living_area_sqft: z.number().nullable(),
  lot_area_sqft: z.number().nullable(),
  source: propertySourceSchema,
  status: propertyStatusSchema,
  property_type: propertyTypeSchema,
  img_src: z.string().nullable(),
  hoa_fee: z.number().nullable(),
  year_built: z.number().nullable(),
  parcel_number: z.string().nullable(),
  brokerage_name: z.string().nullable(),
  legal_description: z.string().nullable(),
  broker_phone_number: z.string().nullable(),
  is_fsbo: z.boolean().nullable(),
  zoning: z.string().nullable(),
  description: z.string().nullable(),
  condition_analysis: propertyConditionSchema.nullable(),
  condition_analysis_description: z.string().nullable(),
  zestimate: z.number().nullable(),
  rent_zestimate: z.number().nullable(),
  coordinates: z.string().optional().nullable(),
});

export type Property = z.infer<typeof propertySchema>;
