import { Box, Button, Flex, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { Organisation } from 'shared/db';
import {
  Products,
  useOrganisationLocations,
  useUpdateOrganisationLocations,
} from 'fe/queries';
import { LocationsSelector } from 'fe/feature/layout';
import { InputWrapper } from 'fe/ui/shared';
import { skipToken } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';

interface LocationsFormProps {
  organisation?: Organisation;
}

export function LocationsForm({ organisation }: LocationsFormProps) {
  const {
    data: organisationLocations,
    isLoading: isLoadingOrganisationLocations,
  } = useOrganisationLocations(
    organisation ? { organization_id: organisation.id } : skipToken,
  );
  const {
    mutateAsync: updateOrganisationLocations,
    isPending: isLoadingLocationsUpdate,
  } = useUpdateOrganisationLocations();

  const zipCodes =
    organisationLocations?.map((location) => location.zip_code.toString()) ||
    [];

  // only calculate once memo
  const states = new Set(
    organisationLocations
      ?.filter((location) => location.state_id)
      .map((location) => location.state_id!) || [],
  );

  const form = useForm({
    initialValues: {
      locations: zipCodes,
    },
  });

  useEffect(() => {
    if (!organisation) return;
    form.setValues({
      locations: zipCodes,
    });
  }, [organisation, organisationLocations]);

  const submitForm = form.onSubmit(async (values) => {
    await updateOrganisationLocations({
      organization_id: organisation?.id || 0,
      zipCodes: values.locations.map(Number),
    });

    notifications.show({
      title: 'Locations updated',
      message: 'Locations have been updated',
      color: 'green',
    });
  });

  return (
    <Box mx="auto">
      <Flex>
        <form onSubmit={submitForm} style={{ width: '100%' }}>
          <InputWrapper
            title="Locations available"
            description="What locations are available for this organisation?"
          >
            {isLoadingOrganisationLocations ? (
              <Loader />
            ) : (
              <LocationsSelector
                inputProps={form.getInputProps('locations')}
                organisationId={organisation?.id || 0}
                type={Products.LISTINGS}
                defaultStates={Array.from(states)}
              />
            )}
          </InputWrapper>

          <Button type="submit" loading={isLoadingLocationsUpdate}>
            Save changes
          </Button>
        </form>
      </Flex>
    </Box>
  );
}
