import {
  Navbar,
  Flex,
  Text,
  ScrollArea,
  Box,
  ActionIcon,
  Image,
  Tooltip,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArrowRight,
  IconChartArrowsVertical,
  IconInbox,
  IconPlus,
  IconSearch,
  IconSettings,
  TablerIconsProps,
} from '@tabler/icons-react';
import {
  NavigateOptions,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router';
import { OrganizationSwitch } from '../OrganisationSwitch';
import { useCampaigns, useGlobalStore, useOrganisationStore } from 'fe/queries';
import { SidebarButton } from './SidebarButton';
import { SidebarCampaignButton } from './SidebarCampaignButton';
import LogoLarge from './../Sidebar/LogoLarge.svg';
import Logo from './../Sidebar/Logo.svg';
import { trackToggleSidebarSize } from 'fe/analytics';
import { CampaignForm } from '../CampaignForm/CampaignForm';
import { modals } from '@mantine/modals';

export function Sidebar() {
  const router = useRouterState();
  const navigate = useNavigate();
  const { organisation } = useOrganisationStore();
  const { data: campaigns } = useCampaigns(organisation?.id);
  const { smallMode, toggleSmallModel } = useGlobalStore((state) => ({
    smallMode: state.smallMode,
    toggleSmallModel: state.toggleSmallMode,
  }));

  const openNewCampaignModal = () => {
    modals.open({
      title: 'Create campaign',
      size: 'lg',
      children: <CampaignForm />,
    });
  };

  const links: {
    id: string;
    label: string;
    icon: (props: TablerIconsProps) => JSX.Element;
    navigateProps: NavigateOptions;
  }[] = [
    {
      id: 'leads',
      label: 'Inbox',
      icon: IconInbox,
      navigateProps: {
        to: '/',
      },
    },
    {
      id: 'insights',
      label: 'Insights',
      icon: IconChartArrowsVertical,
      navigateProps: {
        to: '/insights',
      },
    },
    {
      id: 'offer',
      label: 'Offer',
      icon: IconSearch,
      navigateProps: {
        to: '/offer',
      },
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: IconSettings,
      navigateProps: {
        to: '/settings',
      },
    },
  ];

  const isRouteActive = (id: string) =>
    id === ''
      ? router.location.pathname === '/'
      : router.location.pathname.includes(id);

  return (
    <Navbar width={{ base: smallMode ? 64 : 250 }}>
      <Flex direction="column" justify="space-between" h="100%">
        <Flex style={{ flex: 1 }} direction="column">
          <Flex
            key={'logo'}
            align="center"
            justify="space-between"
            h={50}
            pl="lg"
          >
            {smallMode ? (
              <Image radius="sm" src={Logo} width={30} height={32} />
            ) : (
              <Image radius="sm" src={LogoLarge} width={88} height={32} />
            )}
          </Flex>

          <Flex key="menu" direction="column" gap="xs">
            {links.map((link) => (
              <SidebarButton
                key={link.id}
                active={isRouteActive(link.id)}
                leftSection={<link.icon size={20} stroke={1.5} />}
                reverse
                onClick={() => navigate(link.navigateProps)}
                smallMode={smallMode}
              >
                <Text key={link.id} component="a" size="sm">
                  {link.label}
                </Text>
              </SidebarButton>
            ))}
          </Flex>

          {!smallMode && (
            <Flex
              key="campaigns"
              mt="xl"
              direction="column"
              style={{ margin: '20px 0', flex: 1 }}
              gap="xs"
            >
              <Flex mx={20} gap="xs" justify="space-between">
                <Text fz="xs" fw="bold" transform="uppercase">
                  Campaigns
                </Text>

                <ActionIcon
                  size="xs"
                  variant="light"
                  onClick={openNewCampaignModal}
                >
                  <IconPlus />
                </ActionIcon>
              </Flex>

              <Box pos="relative" style={{ flex: 1 }}>
                <ScrollArea
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {campaigns?.map((campaign) => (
                    <SidebarCampaignButton
                      key={campaign.id}
                      campaign={campaign}
                      isActive={isRouteActive(`campaigns/${campaign.id}`)}
                    />
                  ))}
                </ScrollArea>
              </Box>
            </Flex>
          )}
        </Flex>

        <Flex justify={smallMode ? 'center' : 'flex-end'} px="xl">
          <Tooltip label={smallMode ? 'Expand sidebar' : 'Minimize sidebar'}>
            <ActionIcon
              size="lg"
              variant="outline"
              onClick={() => {
                toggleSmallModel();
                trackToggleSidebarSize();
              }}
            >
              {smallMode ? (
                <IconArrowRight size={20} style={{ pointerEvents: 'none' }} />
              ) : (
                <IconArrowLeft size={20} style={{ pointerEvents: 'none' }} />
              )}
            </ActionIcon>
          </Tooltip>
        </Flex>

        <Flex direction="column" gap="sm">
          <OrganizationSwitch smallMode={smallMode} />
        </Flex>
      </Flex>
    </Navbar>
  );
}
