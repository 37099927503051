import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callApi } from './../utils';
import { generateCampaignRecommendationsEndpoint } from 'shared/api';

export const useGenerateCampaignRecommendations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (campaign_id: number) =>
      callApi(generateCampaignRecommendationsEndpoint, { id: campaign_id }),
    onSuccess: (_, campaign_id) => {
      queryClient.invalidateQueries({
        queryKey: ['recommendations', campaign_id],
      });
    },
  });
};
