import { Button, Flex, Text, Paper, Box, Divider, Drawer } from '@mantine/core';
import { ContactForm } from './ContactForm';
import { formatPhoneNumberToNational } from 'shared/formatter';
import { useDisclosure } from '@mantine/hooks';
import { CallButton } from './../Conversation/CallButton/CallButton';
import {
  isCallingEnabled,
  useLeadProfileStore,
  useAddCallToInbox,
} from 'fe/queries';
import { notifications } from '@mantine/notifications';
import classes from './ContactCard.module.scss';

interface ContactCardProps {
  contact_id: number;
  leadId: number;
  name: string;
  email: string;
  phones: string[];
  sms_enabled: boolean;
}

export function ContactCard({
  contact_id,
  leadId,
  name,
  email,
  phones,
  sms_enabled,
}: ContactCardProps) {
  const [opened, { close: closeModal, open: openModal }] = useDisclosure(false);
  const toggleSmsForm = useLeadProfileStore((state) => state.toggleSmsForm);
  const isEnabled = isCallingEnabled();
  const addCallToInbox = useAddCallToInbox();

  const onSmsClick = () => {
    if (!phones || phones.length === 0) return;
    if (sms_enabled) {
      toggleSmsForm(phones[0]);
    } else {
      notifications.show({
        title: 'Lead needs to contact you first',
        message:
          "This lead needs to text you first before you're able to send him a SMS. This is to comply with 10DLC regulations. You can still call this lead.",
        color: 'red',
        autoClose: 12000,
      });
    }
  };
  return (
    <>
      <Drawer
        opened={opened}
        onClose={closeModal}
        title="Edit contact"
        left={0}
        position="left"
        classNames={{
          inner: classes.innerDrawer,
        }}
      >
        <Box>
          <ContactForm
            leadId={leadId}
            id={contact_id}
            name={name}
            email={email}
            phones={phones}
            onClose={closeModal}
          />
        </Box>
      </Drawer>

      <Paper p="xs" radius="sm" w="100%" withBorder>
        <Flex direction="column" gap="xs">
          <Flex justify="space-between">
            <Text size="sm" fw="bold">
              {name}
            </Text>

            <Divider my="xs" />

            <Flex gap="xs">
              {phones[0] && (
                <>
                  <Button
                    compact
                    size="xs"
                    variant="default"
                    onClick={onSmsClick}
                    disabled={!isEnabled}
                  >
                    SMS
                  </Button>
                </>
              )}

              <Button
                onClick={() => {
                  if (!opened) {
                    openModal();
                    return;
                  }
                  closeModal();
                }}
                size="xs"
                compact
                color="gray"
                variant={opened ? 'default' : 'subtle'}
              >
                {opened ? 'Close' : 'Edit'}
              </Button>
            </Flex>
          </Flex>

          <Flex direction="column" gap="xs">
            <Text size="xs" c="dimmed">
              Email
            </Text>

            <Text size="sm">{email}</Text>
          </Flex>

          {phones && phones.length > 0 && (
            <Flex direction="column" gap="xs">
              <Text size="xs" c="dimmed">
                Phones
              </Text>

              <Flex gap="xs" direction="column">
                {phones.map((phone) => (
                  <Paper key={phone} withBorder p={4}>
                    <Flex justify="space-between">
                      <Text size="sm">
                        {formatPhoneNumberToNational(phone)}
                      </Text>

                      <CallButton
                        name={name}
                        number={phone}
                        contactId={contact_id}
                        onCall={() => addCallToInbox(leadId)}
                      />
                    </Flex>
                  </Paper>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Paper>
    </>
  );
}
