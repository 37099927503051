import { isNotEmpty, useForm } from '@mantine/form';
import { useEffect } from 'react';
import {
  useCampaignLocations,
  useCreateCampaign,
  useOrganisationStore,
  useUpdateCampaign,
} from 'fe/queries';
import { Products, Campaign } from 'shared/db';
import { useNavigate } from '@tanstack/react-router';
import { notifications } from '@mantine/notifications';
import { skipToken } from '@tanstack/react-query';

export interface CampaignFormValues {
  name: string;
  type: string | null;
  locations: string[];
  price_discount: number;
  offers_require_approval: boolean;
  active: boolean;
  daily_offers_limit: number;
  workflow_id: number | null;
  contract_id: number | null;
}

interface CampaignFormProps {
  campaign?: Campaign;
}

export function useCampaignForm({ campaign }: CampaignFormProps) {
  const { mutateAsync: create, isPending: isCreating } = useCreateCampaign();
  const { mutateAsync: update, isPending: isUpdating } = useUpdateCampaign();
  const { data: campaignZipCodes } = useCampaignLocations(
    campaign ? { campaign_id: campaign.id } : skipToken,
  );

  const navigate = useNavigate();

  const { organisation } = useOrganisationStore();

  const form = useForm<CampaignFormValues>({
    initialValues: {
      name: campaign?.name || '',
      type: campaign?.type || Products.LISTINGS,
      locations: campaignZipCodes?.map(String) || [],
      price_discount: campaign?.price_discount || 75,
      offers_require_approval: campaign?.offers_require_approval || false,
      active: campaign?.active || true,
      daily_offers_limit: campaign?.daily_offers_limit || 10,
      workflow_id: campaign?.workflow_id || null,
      contract_id: campaign?.contract_id || null,
    },
    validate: {
      name: isNotEmpty(),
      locations: isNotEmpty(),
      price_discount: isNotEmpty(),
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    const { locations, ...formValues } = values;
    if (campaign) {
      await update({
        ...campaign,
        campaign_id: campaign.id,
        ...formValues,
        zip_codes: locations.map(Number),
      });

      notifications.show({
        title: 'Campaign updated',
        message: `Campaign ${values.name} was successfully updated`,
      });
      return;
    }
    const createdCampaign = await create({
      ...formValues,
      active: true,
      organization_id: organisation?.id || 0,
      zip_codes: locations.map(Number),
    });
    navigate({
      to: `/campaigns/$campaignId`,
      params: {
        campaignId: String(createdCampaign.id),
      },
    });
  });

  const isLoading = isCreating || isUpdating;
  useEffect(() => {
    if (!campaignZipCodes || campaignZipCodes.length === 0) return;
    form.setValues({
      ...campaign,
      locations: campaignZipCodes?.map(String) || [],
    });
  }, [campaign, campaignZipCodes]);

  return {
    isLoading,
    onSubmit,
    form,
  };
}
