import { supabase } from '../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { trpc } from '../setup';
import { uploadProfilePicture } from 'shared/db';

export const useUser = () => {
  const fetchUserData = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return user;
  };

  return useQuery({
    queryKey: ['user'],
    queryFn: fetchUserData,
  });
};

export const useProfile = trpc.user.getProfile.useQuery;

export const useUserProfile = (user_id?: string) =>
  trpc.user.getProfile.useQuery(user_id ? { user_id } : undefined, {
    retry: false,
  });

export const useCreateUserProfile = () => {
  const utils = trpc.useUtils();
  return trpc.user.createProfile.useMutation({
    onSuccess: (data) => {
      utils.user.getProfile.setData(undefined, data);
    },
  });
};
export const useUpdateUserProfile = () => {
  const utils = trpc.useUtils();
  return trpc.user.updateProfile.useMutation({
    onSuccess: (data) => {
      utils.user.getProfile.setData(undefined, data);
    },
  });
};

interface UploadParams {
  userId: string;
  file: File;
}

export const useUploadProfilePicture = () =>
  useMutation({
    mutationFn: async ({ userId, file }: UploadParams) =>
      uploadProfilePicture(supabase, userId, file),
  });

export interface User {
  id?: string;
  email: string;
}

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => supabase.auth.signOut(),
    onSuccess: async () => {
      await navigate({ to: '/' });
      window.location.reload();
    },
  });
};

export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (email: string) => {
      return supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.origin,
        },
      });
    },
    onSuccess: () => {
      window.location.hash = '';
      navigate({ to: '/' });
    },
  });
};

interface VerifyOtpParams {
  email: string;
  otp: string;
}

export const useVerifyOtp = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ email, otp }: VerifyOtpParams) => {
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });

      if (error) {
        return false;
      }

      return data;
    },
    onSuccess: () => {
      navigate({ to: '/' });
    },
  });
};
