import { Button } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import { usePhoneStore, isCallingEnabled } from 'fe/queries';

interface CallButtonProps {
  name: string;
  number: string;
  contactId: number;
  onCall: () => void;
}

export function CallButton({
  name,
  number,
  contactId,
  onCall,
}: CallButtonProps) {
  const isEnabled = isCallingEnabled();
  const makeCall = usePhoneStore((s) => s.makeCall);

  return (
    <Button
      disabled={!isEnabled}
      onClick={() => {
        makeCall({ name, number, contactId });
        onCall();
      }}
      size="xs"
      compact
      variant="subtle"
      leftIcon={<IconPhone size={16} />}
    >
      CALL
    </Button>
  );
}
