import { ActionIcon, Box, Flex, Paper } from '@mantine/core';
import { WorkflowStep } from 'shared/db';
import { Typography } from 'fe/ui/shared';
import { IconPencil, IconPlus, IconX } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { WorkflowStepForm } from './WorkflowStepForm';
import { useDeleteWorkflowStep, useWorkflowSteps } from 'fe/queries';

interface WorkflowStepsProps {
  workflowId: number;
}

export function WorkflowSteps({ workflowId }: WorkflowStepsProps) {
  const { data: steps } = useWorkflowSteps(workflowId);
  const { mutateAsync: deleteWorkflowStep, isPending: isPendingDelete } =
    useDeleteWorkflowStep();

  const open = (workflowStep?: WorkflowStep) => {
    modals.open({
      size: 'xl',
      title: workflowStep ? 'Update workflow step' : 'Create workflow step',
      children: (
        <WorkflowStepForm
          onSuccess={() => modals.closeAll()}
          workflowStep={workflowStep}
          workflowId={workflowId}
          isFirstStep={steps?.length === 0 || workflowStep?.wait_days === 0}
        />
      ),
    });
  };

  return (
    <Box px="xs">
      <Flex direction="row" align="center" gap="xl" mb="lg">
        <Typography fz="xs">Workflow Steps</Typography>

        <Flex gap="xs">
          <ActionIcon variant="outline" color="brand" onClick={() => open()}>
            <IconPlus size="1.125rem" />
          </ActionIcon>
        </Flex>
      </Flex>

      {steps?.map((step) => (
        <Paper p="xs" w="100%" radius="md" bg="#FAFAFA" mb="sm" key={step.id}>
          <Flex justify="space-between" w="100%" align="center">
            <Flex direction="column">
              <Typography>{step.name}</Typography>
            </Flex>

            <Flex gap="xs">
              <ActionIcon
                variant="outline"
                color="brand"
                onClick={() => open(step)}
              >
                <IconPencil size="1.125rem" />
              </ActionIcon>

              {step.wait_days > 0 && (
                <ActionIcon
                  variant="light"
                  color="gray"
                  loading={isPendingDelete}
                  onClick={() =>
                    deleteWorkflowStep({ workflow_step_id: step.id })
                  }
                >
                  <IconX size="1.125rem" />
                </ActionIcon>
              )}
            </Flex>
          </Flex>
        </Paper>
      ))}
    </Box>
  );
}
