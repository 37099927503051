export const toTitleCase = (phrase: string) => {
  if (!phrase) {
    return '';
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export function extractAddressAndZip(input: string) {
  const regex = /^(.+),\s*[^,]+,\s*[A-Z]{2}\s*(\d{5})$/;
  const match = input.match(regex);

  if (match) {
    const address = match[1];
    const zipCode = match[2];
    return { address, zipCode };
  } else {
    throw new Error('Input string is not in the expected format.');
  }
}

export function getUrlExtension(url: string) {
  const extension = url.split(/[#?]/)[0].split('.').pop();
  return extension ? extension.trim() : '';
}
export const isUrlImage = (url: string | null) => {
  if (!url) {
    return false;
  }
  const extension = getUrlExtension(url).toLowerCase();
  return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
};

export function getInitials(fullName: string): string {
  return fullName
    .split(' ')
    .map((name) => name[0]?.toUpperCase() || '')
    .join('');
}

export const stripHtml = (html: string) => html.replace(/<\/[^>]+(>|$)/g, '');
