import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callApi } from './../utils';
import { campaignScheduleAutoOfferEndpoint } from 'shared/api';
import { CampaignListing } from 'shared/db';
import { trpc } from '../setup';

export const useAnalyzeProperty =
  trpc.organization.campaigns.analyzeProperty.useMutation;

export const useScheduleOffer = () => {
  const queryClient = useQueryClient();

  return trpc.organization.workflow.scheduleOffer.useMutation({
    onSuccess: (_, params) => {
      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== params.street_address ||
              offer.zip_code !== params.zip_code,
          ),
      );
    },
  });
};

interface AutoOfferParams {
  street_address: string;
  zip_code: number;
}

export const useScheduleAutoOffer = (campaign_id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: AutoOfferParams) => {
      return callApi(
        campaignScheduleAutoOfferEndpoint,
        { campaign_id },
        { ...params },
      );
    },
    onSuccess: (_, params) => {
      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== params.street_address ||
              offer.zip_code !== params.zip_code,
          ),
      );
    },
  });
};
