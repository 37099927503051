import { useQuery } from '@tanstack/react-query';
import { supabase } from '../utils';
import { format, startOfToday, startOfTomorrow } from 'date-fns';
import { trpc } from '../setup';

export const useDownloadOfferContract = () => {
  return trpc.inbox.files.downloadPotentialContract.useMutation({
    onSuccess: (data) => {
      const a = document.createElement('a');
      a.href = 'data:application/pdf;base64,' + data.content;
      a.download = `Contract.pdf`;
      a.click();
    },
  });
};

export const useFetchOfferEmailContent =
  trpc.organization.campaigns.fetchOfferEmailContent.useMutation;

export interface CampaignOffer {
  id: string;
  campaign_id: number;
  property_zpid: string;
  approved: boolean;
  property_address: string;
  property_price: string;
}

export async function fetchCampaignOffers(
  organisationId: number,
): Promise<CampaignOffer[]> {
  const formatSupabase = (date: Date) => format(date, 'yyyy-MM-dd');
  const { data, error } = await supabase
    .from('leads')
    .select('*, campaigns!inner(*)')
    .eq('campaigns.organisation_id', organisationId)
    .eq('approved', true)
    .gte('created_at', formatSupabase(startOfToday()))
    .lt('created_at', formatSupabase(startOfTomorrow()));

  if (error) {
    throw new Error("Couldn't fetch campaign offers");
  }

  return data;
}

export const useCampaignOffers = (organisationId: number) =>
  useQuery({
    queryKey: ['campaignOffers', organisationId],
    queryFn: () => fetchCampaignOffers(organisationId),
  });
