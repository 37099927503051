import { ApiEndpoint } from '../types';
import { z } from 'zod';

const scheduleAutoOfferRouteSchema = z.object({
  campaign_id: z.number(),
});

const scheduleAutoOfferParamsSchema = z.object({
  street_address: z.string().min(1),
  zip_code: z.number().positive(),
});

export const campaignScheduleAutoOfferEndpoint: ApiEndpoint<
  typeof scheduleAutoOfferRouteSchema,
  typeof scheduleAutoOfferParamsSchema
> = {
  apiUrl: '/campaigns/:campaign_id/scheduleAutoOffer',
  clientUrl: ({ campaign_id }) => `/campaigns/${campaign_id}/scheduleAutoOffer`,
  method: 'POST',
  schema: scheduleAutoOfferParamsSchema,
};
