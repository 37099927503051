import { notifications } from '@mantine/notifications';
import { createClient } from '@supabase/supabase-js';
import { ApiEndpoint } from 'shared/api';
import { z, ZodError, ZodType } from 'zod';

export const supabase = createClient(
  import.meta.env['VITE_SUPABASE_URL'] || 'https://test.com',
  import.meta.env['VITE_SUPABASE_ANON_KEY'] || 'test',
);
export interface API_ERROR {
  error: string;
}

type InferParams<T extends ApiEndpoint<any, any>> =
  T extends ApiEndpoint<infer P, any> ? z.infer<P> : never;
// type InferPayload<T extends ApiEndpoint<any, any>> =
//   T extends ApiEndpoint<any, infer S > ? z.infer<S> : never;

type InferPayload<T extends ApiEndpoint<any, any>> =
  T extends ApiEndpoint<any, infer S>
    ? S extends ZodType<any, any, any>
      ? z.infer<S>
      : never
    : never;

export async function callApi<T extends ApiEndpoint<any, any>>(
  endpoint: T,
  urlParams: InferParams<T>,
  payload?: InferPayload<T>,
): Promise<any> {
  // eslint-disable-next-line no-useless-catch
  try {
    if (endpoint.schema) {
      endpoint.schema.parse(payload);
    }
    const session = await supabase.auth.getSession();
    if (!session || !session.data.session) {
      throw new Error('Not logged in callApi');
    }
    const urlBase = import.meta.env['VITE_NEW_API_URL'];
    const response = await fetch(urlBase + endpoint.clientUrl(urlParams), {
      method: endpoint.method,
      body: payload ? JSON.stringify(payload) : null,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${session.data.session.access_token}`,
      },
    });
    const jsonResponse = await response.json();

    if (!response.ok) {
      notifications.show({
        title: 'Error',
        message: jsonResponse.message || 'An error occurred',
        color: 'red',
      });
      throw new Error('error');
    } else {
      return jsonResponse;
    }
  } catch (error) {
    if (error instanceof ZodError) {
      const errorMessages = error.errors.map((issue: any) => ({
        message: `${issue.path.join('.')}: ${issue.message}`,
      }));
      console.error('error messages: ', errorMessages);
    }
    throw error;
  }
}
